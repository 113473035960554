define("discourse/plugins/discourse-data-explorer/discourse/templates/group-reports-index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="user-content">
    <table class="group-reports">
      <thead>
        <th>
          {{i18n "explorer.report_name"}}
        </th>
        <th>
          {{i18n "explorer.query_description"}}
        </th>
        <th>
          {{i18n "explorer.query_time"}}
        </th>
      </thead>
      <tbody>
        {{#each this.model.queries as |query|}}
          <tr>
            <td>
              <LinkTo
                @route="group.reports.show"
                @models={{array this.group.name query.id}}
              >
                {{query.name}}
              </LinkTo>
            </td>
            <td>{{query.description}}</td>
            <td>
              {{#if query.last_run_at}}
                {{bound-date query.last_run_at}}
              {{/if}}
            </td>
          </tr>
        {{/each}}
      </tbody>
    </table>
  </section>
  */
  {
    "id": "gQOnc0bK",
    "block": "[[[10,\"section\"],[14,0,\"user-content\"],[12],[1,\"\\n  \"],[10,\"table\"],[14,0,\"group-reports\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"explorer.report_name\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"explorer.query_description\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"explorer.query_time\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"model\",\"queries\"]]],null]],null],null,[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"td\"],[12],[1,\"\\n            \"],[8,[39,3],null,[[\"@route\",\"@models\"],[\"group.reports.show\",[28,[37,4],[[30,0,[\"group\",\"name\"]],[30,1,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,1,[\"name\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,\"\\n\"],[41,[30,1,[\"last_run_at\"]],[[[1,\"              \"],[1,[28,[35,6],[[30,1,[\"last_run_at\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"query\"],false,[\"i18n\",\"each\",\"-track-array\",\"link-to\",\"array\",\"if\",\"bound-date\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/templates/group-reports-index.hbs",
    "isStrictMode": false
  });
});